import React from "react";
import "../styles/resume.css";

const resume = () => {
  return (
    <div className="resume-container">
      <div className="resume-header">
        <h1>Education</h1>
      </div>
      <div className="resume-content">
        <div className="resume-content-top">
          <h2>BACHELOR OF ARTS IN COMMUNICATIONS,</h2>
          <h3>UNIVERSITY OF MISSOURI KANSAS CITY</h3>
        </div>
        <p className="resume-content-desc">
          Emphasis in mass communications and journalism, Writer for University
          News newspaper, Current 4.0 GPA
        </p>
      </div>
      <div className="resume-content">
        <div className="resume-content-top">
          <h2>ASSOCIATE OF SCIENCES IN BUSINESS,</h2>
          <h3>LEWIS AND CLARK COMMUNITY COLLEGE</h3>
        </div>
        <p className="resume-content-desc">Graduated with 3.85 GPA</p>
      </div>
      <div className="resume-header">
        <h1>Experience</h1>
      </div>
      <div className="resume-content">
        <div className="resume-content-top">
          <h2>INTERN,</h2>
          <h3>THE DONNA REED FOUNDATION</h3>
        </div>
        <p className="resume-content-desc">
          <ul>
            <li>
              Implemented mass communication skills through generating 2021 PR
              campaign content.
            </li>
            <li>
              Played instrumental role in developing the Donna Reed internship
              program.
            </li>
          </ul>
        </p>
      </div>
      <div className="resume-content">
        <div className="resume-content-top">
          <h2>INTERN,</h2>
          <h3>SLAGLE FINANCIAL</h3>
        </div>
        <p className="resume-content-desc">
          <ul>
            <li>
              Maintained the necessary organization through importing and
              uploading client files to database.
            </li>
            <li>
              Communicated with partner firms for information necessary to
              prepare client portfolios.
            </li>
            <li>
              Aided in event preparation and solidified relationships with
              clients through face-to-face interaction.
            </li>
          </ul>
        </p>
      </div>
      <div className="resume-content">
        <div className="resume-content-top">
          <h2>BARTENDER / SERVER,</h2>
          <h3>AIXOIS FRENCH BISTRO, SEASONS 52, 54TH STREET</h3>
        </div>
        <p className="resume-content-desc">
          <ul>
            <li>
              Organized private dining events with precise execution of
              contracted details.
            </li>
            <li>
              Developed leadership skills through delegation of closing tasks,
              training new team members, and offering helpful performance
              feedback.
            </li>
            <li>
              Created a memorable dining experience for guests under high
              pressure time constraints.
            </li>
            <li>
              Offered friendly, accurate service and anticipated the needs of
              guests.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default resume;
