import React from "react";
import "../styles/about.css";
import Layout from "../components/layout";
import AboutHeader from "../components/aboutHeader";
import Resume from "../components/resume";

function about() {
  return (
    <Layout
      title="Joeli Toscano | About"
      description="Joeli Toscano - Journalist. About Joeli Toscano living and working in Kansas City Missouri. Joeli Toscano's resume, work history, and contact Joeli Toscano."
    >
      <div className="about-container">
        <AboutHeader />
        <Resume />
      </div>
    </Layout>
  );
}

export default about;
