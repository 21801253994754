import React from "react";
import "../styles/about-header.css";
import { StaticImage } from "gatsby-plugin-image";

const aboutHeader = () => {
  return (
    <div className="about-header">
      <div className="header-left">
        <div className="about-line-top"></div>
        <div className="about-line-left"></div>
        <div className="header-text">
          My work aims to share detailed accounts of the human experience and
          cultural insights as I pave the path of an existence that dares to
          explore the boundaries of comfort.
          <br />
          <br />
          My passion is connecting with other people and learning their opinions
          and perceptions of the world we share.
        </div>
      </div>
      <div className="header-right">
        <div className="top-blur">
          <StaticImage
            src="../images/about-header.jpg"
            className="header-img"
            loading="eager"
            placeholder="blurred"
          />
        </div>
      </div>
    </div>
  );
};

export default aboutHeader;
